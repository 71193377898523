export default {
  heading: 'Gorditas, serif',
  body: 'Din, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Special Elite, serif',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Gorditas: 400, 700',
    'Special Elite'
  ],
  customFamilies: ['Din'],
  customUrls: ['https://www.gonation.biz/fonts/din/din.css']
}
